import React, { Component } from 'react';
import './App.css';
import SubscriptionEditor from './SubscriptionEditor';
import SerNumEditor from './SerNumEditor';

export default class Licenses extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    
    return (
      <div className="Licenses">
        <div className="layoutFlow">
          <div className="hasNestedComps elSubscriptions">
            <div>
              <SubscriptionEditor ref={(el)=> this._elSubscriptions = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="hasNestedComps elSerialNumbers">
            <div>
              <SerNumEditor ref={(el)=> this._elSerialNumbers = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
