import React, { Component } from 'react';
import './App.css';
import ActivateLicense from './ActivateLicense';
import GenerateSharecode from './GenerateSharecode';
import ActivateCoach from './ActivateCoach';
import TheStackApp from './TheStackApp';
import btn_icon_967720 from './images/btn_icon_967720.png';
import img_elTheStack from './images/HomeScreen_elTheStack_664718.png';
import img_elLogo from './images/HomeScreen_elLogo_816790.png';
import Menu from './Menu';

// UI framework component imports
import Appbar from 'muicss/lib/react/appbar';

export default class RegistrationScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, sharecode

  constructor(props) {
    super(props);
    
    this.state = {
      menu_isOpen: false,
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['user'];
      let serviceOptions = this.props.appActions.serviceOptions_user;
      if ( !this.props.appActions.dataSheetLoaded['user']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("users/$slot('ds_userid')", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase_thestack482f9(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['user'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elMenuButton = async (ev) => {
    this.toggle_menu();
  
  }
  
  
  toggle_menu = () => {
    this.setState({menu_isOpen: !this.state.menu_isOpen});
  }
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    // eslint-disable-next-line
    const dataSheet_user = this.props.dataSheets['user'];
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    
    const style_elMenuButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_967720+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '89.562%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTheStack = {
      height: 'auto',
     };
    const style_elLogo = {
      height: 'auto',
     };
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.props.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    const style_menu = {
      pointerEvents: (this.state.menu_isOpen) ? 'auto' : 'none'
    }
    
    return (
      <div className="AppScreen RegistrationScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="hasNestedComps elActivateLicense">
            <div>
              <ActivateLicense {...dataSheet_user.items[0]} ref={(el)=> this._elActivateLicense = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="hasNestedComps elGenerateSharecode">
            <div>
              <GenerateSharecode {...dataSheet_user.items[0]} ref={(el)=> this._elGenerateSharecode = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="hasNestedComps elActivateCoach">
            <div>
              <ActivateCoach {...dataSheet_user.items[0]} ref={(el)=> this._elActivateCoach = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
          
          <div className="hasNestedComps elTheStackApp">
            <div>
              <TheStackApp ref={(el)=> this._elTheStackApp = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        <Appbar className="navBar">
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <button className="actionFont elMenuButton" style={style_elMenuButton} onClick={this.onClick_elMenuButton}  />
            <img className="elTheStack" style={style_elTheStack} src={img_elTheStack} alt=""  />
            <img className="elLogo" style={style_elLogo} src={img_elLogo} alt=""  />
            <div className="elProgress">
              <svg className={cssClass_progress}>
                <circle className="circularProgress-animatedPath" style={{stroke: '#fec10d'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
          </div>
        </div>
        <div className="drawer menu" style={style_menu} onClick={this.toggle_menu}>
          <div className={"drawerContent" + (this.state.menu_isOpen ? " drawerOpen": " drawerClosedLeft")} onClick={(e) => {e.stopPropagation();}}>
            <Menu appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} closeDrawer={this.toggle_menu} />
          </div>
        </div>
      </div>
    )
  }
  
}
