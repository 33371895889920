import React, { Component } from 'react';
import './App.css';
import img_elTheStack from './images/HomeScreen_elTheStack_664718.png';
import img_elTheStackLogo from './images/HomeScreen_elLogo_816790.png';
import FirebaseLogin from './FirebaseLogin';
import firebase from 'firebase';
import firestore from 'firebase/firestore';

export default class LoginScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo

  constructor(props) {
    super(props);
    
    this.state = {
      text2: (<div>Everything You Need to Train For Speed</div>),
      text2_plainText: "Everything You Need to Train For Speed",
      text: (<div>Copyright (C) 2021 The Stack System, LP</div>),
      text_plainText: "Copyright (C) 2021 The Stack System, LP",
    };
  }

  componentDidMount() {
    // Check if Firebase login has been completed.
    let unregisterAuthObserver = firebase.auth().onAuthStateChanged(
      (user) => {
        if (user) {
          if (firebase.auth().currentUser) {
            if (this._elFirebaseLogin)
              this._elFirebaseLogin.saveCurrentUserDataInApp();
            
            let targetTable_loginGoToPage = (targetId) => {
              if (targetId === 'licensed')
                return 'registration';
              else if (targetId === 'not-licensed')
                return 'registration';
            }
            
            let transformTargetScreenValue_loginGoToPage = (input) => {
              // This function modifies the target screen value of switch 'LoginGoToPage'.
              // There is a variable named 'input' that provides the target screen's name.
              // The function may implement custom logic to return a screen name dynamically based on the input value.
              //
              if (!input || input==='') {
                return 'not-licensed';
              }
              return 'licensed';
            }
            
            
            sessionStorage.setItem('loginStatus_login', 'active');
            this.props.appActions.goToScreen(targetTable_loginGoToPage(transformTargetScreenValue_loginGoToPage((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_username'] : ''))));
            
          }
        }
        unregisterAuthObserver();
      }
    );
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elTerms = async (ev) => {
    window.open('https://www.thestacksystem.com/terms-of-use', '_blank');
  
  }
  
  
  onClick_elPrivacy = async (ev) => {
    window.open('https://www.thestacksystem.com/privacy-policy', '_blank');
  
  }
  
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    const style_elTheStack = {
      height: 'auto',
     };
    const style_elTheStackLogo = {
      height: 'auto',
     };
    const style_elText2 = {
      color: '#212121',
      textAlign: 'center',
     };
    const style_elLogin = {
      pointerEvents: 'auto',
     };
    
    const style_elTerms = {
      display: 'block',
      textTransform: 'uppercase',
      color: '#1436ff',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elPrivacy = {
      display: 'block',
      textTransform: 'uppercase',
      color: '#1436ff',
      textAlign: 'right',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elText = {
      fontSize: 11.4,
      color: '#212121',
      textAlign: 'center',
     };
    const style_elFooterLine = {
      width: '100%',
      height: '100%',
     };
    const style_elFooterLine_outer = {
      backgroundColor: '#feecb7',
     };
    
    return (
      <div className="AppScreen LoginScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elTheStack">
            <img style={style_elTheStack} src={img_elTheStack} alt=""  />
          </div>
          
          <div className="elTheStackLogo">
            <img style={style_elTheStackLogo} src={img_elTheStackLogo} alt=""  />
          </div>
          
          <div className="elText2">
            <div className="headlineFont" style={style_elText2}>
              <div>{this.state.text2}</div>
            </div>
          </div>
          
          <div className="elLogin">
            <div style={style_elLogin}>
              <FirebaseLogin ref={(el)=> this._elLogin = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
            </div>
          </div>
        </div>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <button className="actionFont elTerms" style={style_elTerms} onClick={this.onClick_elTerms} >
              Terms of Use
            </button>
            <button className="actionFont elPrivacy" style={style_elPrivacy} onClick={this.onClick_elPrivacy} >
              Privacy Policy
            </button>
            <div className="systemFontRegular  elText" style={style_elText}>
              <div>{this.state.text}</div>
            </div>
            <div className="elFooterLine" style={style_elFooterLine_outer}>
              <div style={style_elFooterLine} />
            </div>
            
          </div>
        </div>
      </div>
    )
  }
  
}
