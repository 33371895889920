import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/ActivateLicense_elImage_873929.png';
import btn_icon_472230 from './images/btn_icon_472230.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class ActivateLicense extends Component {

  // Properties used by this component:
  // serialnumberLicense

  constructor(props) {
    super(props);
    
    this.state = {
      thestacklicense: (<div>I have a serial number to register</div>),
      thestacklicense_plainText: "I have a serial number to register",
      license: (<div> </div>),
      license_plainText: " ",
      licenseField: (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_license'] : '') || '',
      activateresult: (<div> </div>),
      activateresult_plainText: " ",
      process: (<div>Once you register your serial number, download and log in to the TheStack App and get started with your training!</div>),
      process_plainText: "Once you register your serial number, download and log in to the TheStack App and get started with your training!",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  textInputChanged_elLicenseField = (event) => {
    this.setState({licenseField: event.target.value});
    
    this.props.appActions.updateDataSlot("ds_license", event.target.value);
  }
  
  onClick_elActivate = async (ev) => {
    //import firebase from 'firebase';
    const firebase = require("firebase")
    require("firebase/functions");
    
    this.props.appActions.setState({loading: true});
    
    var code = this.props.appActions.dataSlots['ds_license']
    var device = navigator.userAgent
    var name = this.props.appActions.dataSlots['ds_username']
    
    const callableReturnMessage = firebase.functions().httpsCallable('activate');
    callableReturnMessage({
      "code": code,
      "isSubscription": false,
      "device": device,
      "name": name
    }).then((result) => {
      // {"data":{"license":"RC123","activationLimit":7,"profileLimit":8}}
      this.props.appActions.updateDataSlot('ds_license', '');
      this.props.appActions.updateDataSlot('ds_activateResult', `Successfully activated your license. (${result.data.activationLimit})`);
      this.props.appActions.updateDataSlot('ds_userid', this.props.appActions.dataSlots['ds_userid']);
      console.log(result.data);
      this.props.appActions.setState({loading: false});
    }).catch((error) => {
      this.props.appActions.updateDataSlot('ds_activateResult', `error: ${JSON.stringify(error)}`);
      console.log(`error: ${JSON.stringify(error)}`);
      this.props.appActions.setState({loading: false});
    });
    
  
  }
  
  
  onClick_elHelp = async (ev) => {
    window.open('https://www.thestacksystem.com/apps/help-center#hc-i-have-thestack-hardware-how-do-i-get-started-training', '_blank');
  
  }
  
  
  render() {
    const style_elTheStackLicense = {
      color: '#212121',
      textAlign: 'center',
     };
    let transformPropValue_license = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      if (!input || input==='') {
        return '';
      }
      return 'Current S/N: ' + input;
    }
    
    const value_license = transformPropValue_license(this.props.serialnumberLicense);
    
    const style_elLicense = {
      color: '#1436ff',
      textAlign: 'center',
     };
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'contain',
     };
    
    const value_licenseField = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_license'] : '');
    
    const style_elLicenseField = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: '#212121',
      textAlign: 'left',
      pointerEvents: 'auto',
     };
    
    const style_elActivate = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_activateResult = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_activateResult'] : '');
    
    const style_elActivateResult = {
      color: '#1436ff',
      textAlign: 'center',
     };
    const style_elProcess = {
      fontSize: 12.3,
      color: '#515151',
      textAlign: 'center',
     };
    
    const style_elHelp = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_472230+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '7.692%',
      paddingLeft: '8%',
      backgroundPosition: '0% 50%',
      color: '#515151',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elCard_outer = {
      backgroundColor: '#fec10d',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    
    return (
      <div className="ActivateLicense appBg">
        <div className="layoutFlow">
          <div className="elTheStackLicense">
            <div className="headlineFont" style={style_elTheStackLicense}>
              <div>{this.state.thestacklicense}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="flowRow flowRow_ActivateLicense_elLicense_31400">
          <div className="elLicense">
            <div className="baseFont" style={style_elLicense}>
              <div>{value_license}</div>
            </div>
          </div>
          
          <div className="elImage">
            <div style={style_elImage} />
          </div>
          
          <div className="elLicenseField">
            <input className="baseFont" style={style_elLicenseField} type="text" placeholder="" onChange={this.textInputChanged_elLicenseField} value={value_licenseField !== undefined ? value_licenseField : ''}  />
          </div>
          
          <div className="elActivate">
            <Button className="actionFont" style={style_elActivate}  color="accent" onClick={this.onClick_elActivate} >
              Register Serial Number
            </Button>
          </div>
          
          </div>
          <div className="elActivateResult">
            <div className="baseFont" style={style_elActivateResult}>
              <div>{value_activateResult}</div>
            </div>
          </div>
          
          <div className="elProcess">
            <div className="systemFontRegular" style={style_elProcess}>
              <div>{this.state.process}</div>
            </div>
          </div>
          
          <div className="elHelp">
            <button className="actionFont" style={style_elHelp} onClick={this.onClick_elHelp} >
              More About Serial Numbers
            </button>
          </div>
          
          <div className="elCard" style={style_elCard_outer}>
            <div className="highlightBg" />
          </div>
        </div>
        
      </div>
    )
  }
  
}
