import React, { Component } from 'react';
import './App.css';

export default class Name extends Component {

  // Properties used by this component:
  // name

  constructor(props) {
    super(props);
    
    this.state = {
      text: (<div> </div>),
      text_plainText: " ",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    let transformPropValue_text = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      if (!input || input === '') {
        return this.props.appActions.dataSlots['ds_username'];
      }
      
      return input;
    }
    
    const value_text = transformPropValue_text(this.props.name);
    
    const style_elText = {
      color: '#212121',
      textAlign: 'center',
     };
    
    return (
      <div className="Name">
        <div className="layoutFlow">
          <div className="elText">
            <div className="baseFont" style={style_elText}>
              <div>{value_text}</div>
            </div>
          </div>
        </div>
        
      </div>
    )
  }
  
}
