import React, { Component } from 'react';
import './App.css';

export default class SNAct2 extends Component {

  // Properties used by this component:
  // order, ordername, latest, note, name, code, user, activationLimit, profileLimit

  constructor(props) {
    super(props);
    
    this.state = {
      latest: (<div>DateTime…</div>),
      latest_plainText: "DateTime…",
      note: (<div>examplenote</div>),
      note_plainText: "examplenote",
      subscription: (<div>GD-0000</div>),
      subscription_plainText: "GD-0000",
      subscriptioncopy: (<div>Uidname</div>),
      subscriptioncopy_plainText: "Uidname",
      uid: (<div>Uid</div>),
      uid_plainText: "Uid",
      order: (<div>Order: 6234696667</div>),
      order_plainText: "Order: 6234696667",
      name: (<div>by: SomeGolfer</div>),
      name_plainText: "by: SomeGolfer",
      uidcopy: (<div>activationsLeft=10</div>),
      uidcopy_plainText: "activationsLeft=10",
      uidcopy2: (<div>profileLimit=5</div>),
      uidcopy2_plainText: "profileLimit=5",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    let transformPropValue_latest = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input;
    }
    
    const value_latest = transformPropValue_latest(this.props.latest);
    
    const style_elLatest = {
      fontSize: 8.5,
      color: '#1436ff',
      textAlign: 'right',
     };
    let transformPropValue_note = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input;
    }
    
    const value_note = transformPropValue_note(this.props.note);
    
    const style_elNote = {
      fontSize: 8.5,
      color: '#fe0d0d',
      textAlign: 'left',
     };
    
    const value_subscription = this.props.code;
    
    const style_elSubscription = {
      fontSize: 16.1,
      color: '#212121',
      textAlign: 'left',
     };
    
    const value_subscriptionCopy = this.props.name;
    
    const style_elSubscriptionCopy = {
      color: '#212121',
      textAlign: 'right',
     };
    let transformPropValue_uid = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input.substring(0,8);
    }
    
    const value_uid = transformPropValue_uid(this.props.user);
    
    const style_elUid = {
      fontSize: 8.5,
      color: '#ff8500',
      textAlign: 'right',
     };
    let transformPropValue_order = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return 'Order: ' + input;
    }
    
    const value_order = transformPropValue_order(this.props.order);
    
    const style_elOrder = {
      fontSize: 9.5,
      color: '#212121',
      textAlign: 'left',
     };
    let transformPropValue_name = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return 'by: ' + input;
    }
    
    const value_name = transformPropValue_name(this.props.ordername);
    
    const style_elName = {
      fontSize: 9.5,
      color: '#212121',
      textAlign: 'left',
     };
    const style_elCard_outer = {
      backgroundColor: '#fec10d',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    let transformPropValue_uidCopy = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return "activationsLeft=" + input;
    }
    
    const value_uidCopy = transformPropValue_uidCopy(this.props.activationLimit);
    
    const style_elUidCopy = {
      fontSize: 8.5,
      color: '#515151',
      textAlign: 'left',
     };
    let transformPropValue_uidCopy2 = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return "profileLimit=" + input;
    }
    
    const value_uidCopy2 = transformPropValue_uidCopy2(this.props.profileLimit);
    
    const style_elUidCopy2 = {
      fontSize: 8.5,
      color: '#515151',
      textAlign: 'left',
     };
    
    return (
      <div className="SNAct2">
        <div className="layoutFlow">
          <div className="elLatest">
            <div className="systemFontRegular" style={style_elLatest}>
              <div>{value_latest}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_SNAct2_elNote_321163">
          <div className="elNote">
            <div className="systemFontRegular" style={style_elNote}>
              <div>{value_note}</div>
            </div>
          </div>
          
          </div>
          <div className="elSubscription">
            <div className="systemFontBold" style={style_elSubscription}>
              <div>{value_subscription}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_SNAct2_elSubscriptionCopy_853095">
          <div className="elSubscriptionCopy">
            <div className="baseFont" style={style_elSubscriptionCopy}>
              <div>{value_subscriptionCopy}</div>
            </div>
          </div>
          
          <div className="elUid">
            <div className="systemFontRegular" style={style_elUid}>
              <div>{value_uid}</div>
            </div>
          </div>
          
          </div>
          <div className="elOrder">
            <div className="systemFontRegular" style={style_elOrder}>
              <div>{value_order}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_SNAct2_elName_824724">
          <div className="elName">
            <div className="systemFontRegular" style={style_elName}>
              <div>{value_name}</div>
            </div>
          </div>
          
          </div>
          <div className="elCard" style={style_elCard_outer}>
            <div className="highlightBg" />
          </div>
          
          <div className="flowRow flowRow_SNAct2_elUidCopy_204824">
          <div className="elUidCopy">
            <div className="systemFontRegular" style={style_elUidCopy}>
              <div>{value_uidCopy}</div>
            </div>
          </div>
          
          <div className="elUidCopy2">
            <div className="systemFontRegular" style={style_elUidCopy2}>
              <div>{value_uidCopy2}</div>
            </div>
          </div>
          
          </div>
        </div>
        
      </div>
    )
  }
  
}
