import React, { Component } from 'react';
import './App.css';
import SubAct2 from './SubAct2';
import SNAct2 from './SNAct2';

export default class Activated extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      title: (<div>Subscriptions</div>),
      title_plainText: "Subscriptions",
      text: (<div>Serial Numbers</div>),
      text_plainText: "Serial Numbers",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    // eslint-disable-next-line
    const dataSheet_SNAct = this.props.appActions.getDataSheet('SNAct');
    // eslint-disable-next-line
    const dataSheet_subAct = this.props.appActions.getDataSheet('subAct');
    const style_elTitle = {
      color: '#212121',
      textAlign: 'center',
     };
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.props.appActions.getDataSheet('subAct').items);
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    const style_elText = {
      color: '#212121',
      textAlign: 'center',
     };
    
    // Source items and any special components used for list/grid element 'list copy'.
    let items_listCopy = [];
    let listComps_listCopy = {};
    items_listCopy = items_listCopy.concat(this.props.appActions.getDataSheet('SNAct').items);
    this._elListCopy_items = [];
    
    const style_elListCopy = {
      height: 'auto',  // This element is in scroll flow
     };
    
    return (
      <div className="Activated appBg">
        <div className="layoutFlow">
          <div className="elTitle">
            <div className="headlineFont" style={style_elTitle}>
              <div>{this.state.title}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elList">
            <ul style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <SubAct2 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'subAct'} dataSheetRow={row} {...{ 'order': row['order'], 'ordername': row['ordername'], 'latest': row['latest'], 'note': row['note'], 'name': row['name'], 'code': row['code'], 'user': row['user'], 'activationLimit': row['activationLimit'], 'profileLimit': row['profileLimit'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </ul>
          </div>
          
          <div className="elText">
            <div className="headlineFont" style={style_elText}>
              <div>{this.state.text}</div>
            </div>
          </div>
          
          <div className="hasNestedComps elListCopy">
            <ul style={style_elListCopy}>
              {items_listCopy.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_listCopy[row._componentId]
                    : <SNAct2 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'SNAct'} dataSheetRow={row} {...{ 'order': row['order'], 'ordername': row['ordername'], 'latest': row['latest'], 'note': row['note'], 'name': row['name'], 'code': row['code'], 'user': row['user'], 'activationLimit': row['activationLimit'], 'profileLimit': row['profileLimit'], }} ref={(el) => {if (el) this._elListCopy_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elListCopy_endMarker = el} />
            </ul>
          </div>
        </div>
        
      </div>
    )
  }
  
}
