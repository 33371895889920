// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_localizationSheet extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;
    
    item = {};
    this.items.push(item);
    item['key'] = "copyright";
    item['en'] = "Copyright (C) 2021 The Stack System, LP";
    
    item = {};
    this.items.push(item);
    item['key'] = "button_logout";
    item['en'] = "Log Out";
    
    item = {};
    this.items.push(item);
    item['key'] = "button_privacy";
    item['en'] = "Privacy Policy";
    
    item = {};
    this.items.push(item);
    item['key'] = "button_terms";
    item['en'] = "Terms of Use";
    
    item = {};
    this.items.push(item);
    item['key'] = "info_downloadapp";
    item['en'] = "Download TheStack App";
    
    item = {};
    this.items.push(item);
    item['key'] = "info_everythingyouneed";
    item['en'] = "Everything You Need to Train For Speed";
    
    item = {};
    this.items.push(item);
    item['key'] = "subscription_heading";
    item['en'] = "I have a subscription to activate";
    
    item = {};
    this.items.push(item);
    item['key'] = "subscription_button";
    item['en'] = "Activate Subscription";
    
    item = {};
    this.items.push(item);
    item['key'] = "subscription_info";
    item['en'] = "Activate your subscription to start adding players to your stable using TheStack app. Follow the steps to Add a Golfer using a Student`s Share Code (cloud sharing).";
    
    item = {};
    this.items.push(item);
    item['key'] = "subscription_help";
    item['en'] = "More About Subscriptions";
    
    item = {};
    this.items.push(item);
    item['key'] = "sharecode_heading";
    item['en'] = "I want to connect with my coach";
    
    item = {};
    this.items.push(item);
    item['key'] = "sharecode_button";
    item['en'] = "Get my Sharecode";
    
    item = {};
    this.items.push(item);
    item['key'] = "sharecode_info";
    item['en'] = "Generate a share-code and provide it to your coach. Once you are added to their stable, you will be able to log in to TheStack App and begin your training!";
    
    item = {};
    this.items.push(item);
    item['key'] = "sharecode_help";
    item['en'] = "More About Sharecodes";
    
    item = {};
    this.items.push(item);
    item['key'] = "serialnumber_heading";
    item['en'] = "I have a serial number to register";
    
    item = {};
    this.items.push(item);
    item['key'] = "serialnumber_button";
    item['en'] = "Register Serial Number";
    
    item = {};
    this.items.push(item);
    item['key'] = "serialnumber_info";
    item['en'] = "Once you register your serial number, download and log in to the TheStack App and get started with your training!";
    
    item = {};
    this.items.push(item);
    item['key'] = "serialnumber_help";
    item['en'] = "More About Serial Numbers";
    
    item = {};
    this.items.push(item);
    item['key'] = "home_placeholder";
    item['en'] = "HOME PAGE HERE";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_home";
    item['en'] = "Home";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_registration";
    item['en'] = "Registration";
    
    item = {};
    this.items.push(item);
    item['key'] = "menu_admin";
    item['en'] = "Admin";
    
    item = {};
    this.items.push(item);
    item['key'] = "subscriptheader_title";
    item['en'] = "Subscriptions";
    
    item = {};
    this.items.push(item);
    item['key'] = "subscriptheader_validator";
    item['en'] = " Invalid Value(s)";
    
    item = {};
    this.items.push(item);
    item['key'] = "subscriptheader_profiles";
    item['en'] = "Profiles";
    
    item = {};
    this.items.push(item);
    item['key'] = "subscriptheader_activations";
    item['en'] = "Activations";
    
    item = {};
    this.items.push(item);
    item['key'] = "subscriptheader_subscription";
    item['en'] = "Subscription";
    
    item = {};
    this.items.push(item);
    item['key'] = "subscriptheader_subscriptionph";
    item['en'] = "XXXXXXXXX";
    
    item = {};
    this.items.push(item);
    item['key'] = "subscriptheader_profileph";
    item['en'] = "XX";
    
    item = {};
    this.items.push(item);
    item['key'] = "subscriptheader_activationsph";
    item['en'] = "XX";
    
    item = {};
    this.items.push(item);
    item['key'] = "sernumheader_activationsph";
    item['en'] = "XX";
    
    item = {};
    this.items.push(item);
    item['key'] = "sernumheader_profilesph";
    item['en'] = "XX";
    
    item = {};
    this.items.push(item);
    item['key'] = "sernumheader_sernumph";
    item['en'] = "GDXXXXX";
    
    item = {};
    this.items.push(item);
    item['key'] = "sernumheader_sernum";
    item['en'] = "Serial Number (GD[-][P]12345)";
    
    item = {};
    this.items.push(item);
    item['key'] = "sernumheader_activations";
    item['en'] = "Activations";
    
    item = {};
    this.items.push(item);
    item['key'] = "sernumheader_profiles";
    item['en'] = "Profiles";
    
    item = {};
    this.items.push(item);
    item['key'] = "sernumheader_title";
    item['en'] = "Serial Numbers";
    
    item = {};
    this.items.push(item);
    item['key'] = "sernumheader_validator";
    item['en'] = " Invalid Value(s)";
    
    item = {};
    this.items.push(item);
    item['key'] = "namedvalue_name";
    item['en'] = "Gender";
    
    item = {};
    this.items.push(item);
    item['key'] = "namedvalue_value";
    item['en'] = "Male";
    
    item = {};
    this.items.push(item);
    item['key'] = "home_gender";
    item['en'] = "Gender";
    
    item = {};
    this.items.push(item);
    item['key'] = "subscriptheader_order";
    item['en'] = "Order";
    
    item = {};
    this.items.push(item);
    item['key'] = "subscriptheader_name";
    item['en'] = "Name";
    
    item = {};
    this.items.push(item);
    item['key'] = "subscriptioneditor_helpkey";
    item['en'] = "Tier 1 $96 = 5 profiles,  Tier 2 $192 = 15,  Tier 2+ $420 = 40,  Tier 3 $960 = 100";
    
    item = {};
    this.items.push(item);
    item['key'] = "subact2_uidcopy_666605";
    item['en'] = " ";
    
    item = {};
    this.items.push(item);
    item['key'] = "subact2_activationscopy_817832";
    item['en'] = "Actvtns: ";
    
    item = {};
    this.items.push(item);
    item['key'] = "snact2_uidcopy_204824";
    item['en'] = "UID: Uid";
    
    item = {};
    this.items.push(item);
    item['key'] = "snact2_uidcopy2_626633";
    item['en'] = "Actvtns: 10";
    
    let storedItems = localStorage.getItem(this.id);
    if (storedItems != null) {
      this.items = JSON.parse(storedItems);
    }
  }

  addItem(item, options) {
    super.addItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  removeItem(item, options) {
    super.removeItem(item, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  replaceItemByRowIndex(idx, newItem, options) {
    super.replaceItemByRowIndex(idx, newItem, options);
    
    localStorage.setItem(this.id, JSON.stringify(this.items));
  }

  getStringsByLanguage = () => {
    let stringsByLang = {};
    for (let row of this.items) {
      const locKey = row.key;
      for (let key in row) {
        if (key === 'key')
          continue;
        let langObj = stringsByLang[key] || {};
        langObj[locKey] = row[key];
        stringsByLang[key] = langObj;
      }
    }
    return stringsByLang;
  }

}
