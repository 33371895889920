import React, { Component } from 'react';
import './App.css';

export default class SubAct2 extends Component {

  // Properties used by this component:
  // order, ordername, latest, note, name, code, user, activationLimit, profileLimit

  constructor(props) {
    super(props);
    
    this.state = {
      latest: (<div> </div>),
      latest_plainText: " ",
      note: (<div> </div>),
      note_plainText: " ",
      subscription: (<div>REZ6X8NTibezfUScLeG8Tkt9Trv2</div>),
      subscription_plainText: "REZ6X8NTibezfUScLeG8Tkt9Trv2",
      subscriptioncopy: (<div>REZ6X8NTibezfUScLeG8Tkt9Trv2</div>),
      subscriptioncopy_plainText: "REZ6X8NTibezfUScLeG8Tkt9Trv2",
      order: (<div>Order: </div>),
      order_plainText: "Order: ",
      name: (<div>by: </div>),
      name_plainText: "by: ",
      uid: (<div> </div>),
      uid_plainText: " ",
      activations: (<div>activationsLeft=</div>),
      activations_plainText: "activationsLeft=",
      activationscopy: (<div>profileLimit=</div>),
      activationscopy_plainText: "profileLimit=",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  render() {
    let transformPropValue_latest = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input;
    }
    
    const value_latest = transformPropValue_latest(this.props.latest);
    
    const style_elLatest = {
      fontSize: 8.5,
      color: '#1436ff',
      textAlign: 'right',
     };
    let transformPropValue_note = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input;
    }
    
    const value_note = transformPropValue_note(this.props.note);
    
    const style_elNote = {
      fontSize: 8.5,
      color: '#fe0d0d',
      textAlign: 'left',
     };
    
    const value_subscription = this.props.code;
    
    const style_elSubscription = {
      fontSize: 16.1,
      color: '#212121',
      textAlign: 'left',
     };
    
    const value_subscriptionCopy = this.props.name;
    
    const style_elSubscriptionCopy = {
      color: '#212121',
      textAlign: 'right',
     };
    let transformPropValue_order = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return 'Order: ' + input;
    }
    
    const value_order = transformPropValue_order(this.props.order);
    
    const style_elOrder = {
      fontSize: 9.5,
      color: '#212121',
      textAlign: 'left',
     };
    let transformPropValue_name = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return 'by: ' + input;
    }
    
    const value_name = transformPropValue_name(this.props.ordername);
    
    const style_elName = {
      fontSize: 9.5,
      color: '#212121',
      textAlign: 'left',
     };
    const style_elBottomLine_outer = {
      backgroundColor: '#fec10d',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    let transformPropValue_uid = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input.substring(0,8);
    }
    
    const value_uid = transformPropValue_uid(this.props.user);
    
    const style_elUid = {
      fontSize: 8.5,
      color: '#ff8500',
      textAlign: 'right',
     };
    let transformPropValue_activations = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return "activationsLeft=" + input;
    }
    
    const value_activations = transformPropValue_activations(this.props.activationLimit);
    
    const style_elActivations = {
      fontSize: 8.5,
      color: '#515151',
      textAlign: 'left',
     };
    let transformPropValue_activationsCopy = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return "profileLimit=" + input;
    }
    
    const value_activationsCopy = transformPropValue_activationsCopy(this.props.profileLimit);
    
    const style_elActivationsCopy = {
      fontSize: 8.5,
      color: '#515151',
      textAlign: 'left',
     };
    
    return (
      <div className="SubAct2">
        <div className="layoutFlow">
          <div className="elLatest">
            <div className="systemFontRegular" style={style_elLatest}>
              <div>{value_latest}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_SubAct2_elNote_571834">
          <div className="elNote">
            <div className="systemFontRegular" style={style_elNote}>
              <div>{value_note}</div>
            </div>
          </div>
          
          </div>
          <div className="elSubscription">
            <div className="systemFontBold" style={style_elSubscription}>
              <div>{value_subscription}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_SubAct2_elSubscriptionCopy_62458">
          <div className="elSubscriptionCopy">
            <div className="baseFont" style={style_elSubscriptionCopy}>
              <div>{value_subscriptionCopy}</div>
            </div>
          </div>
          
          </div>
          <div className="elOrder">
            <div className="systemFontRegular" style={style_elOrder}>
              <div>{value_order}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_SubAct2_elName_209294">
          <div className="elName">
            <div className="systemFontRegular" style={style_elName}>
              <div>{value_name}</div>
            </div>
          </div>
          
          </div>
          <div className="elBottomLine" style={style_elBottomLine_outer}>
            <div className="highlightBg" />
          </div>
          
          <div className="flowRow flowRow_SubAct2_elUid_419329">
          <div className="elUid">
            <div className="systemFontRegular" style={style_elUid}>
              <div>{value_uid}</div>
            </div>
          </div>
          
          <div className="elActivations">
            <div className="systemFontRegular" style={style_elActivations}>
              <div>{value_activations}</div>
            </div>
          </div>
          
          <div className="elActivationsCopy">
            <div className="systemFontRegular" style={style_elActivationsCopy}>
              <div>{value_activationsCopy}</div>
            </div>
          </div>
          
          </div>
        </div>
        
      </div>
    )
  }
  
}
