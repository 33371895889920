import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
// eslint-disable-next-line
import { Route, Switch, Redirect, withRouter } from 'react-router-dom';
// eslint-disable-next-line
import * as util from 'util';
import './App.css';
import HomeScreen from './HomeScreen.js';
import RegistrationScreen from './RegistrationScreen.js';
import LoginScreen from './LoginScreen.js';
import AdminScreen from './AdminScreen.js';
import DataSheet_localizationSheet from './DataSheet_localizationSheet.js';
import DataSheet_user from './DataSheet_user.js';
import DataSheet_profile from './DataSheet_profile.js';
import DataSheet_subscriptions from './DataSheet_subscriptions.js';
import DataSheet_serialNumbers from './DataSheet_serialNumbers.js';
import DataSheet_gender from './DataSheet_gender.js';
import DataSheet_subAct from './DataSheet_subAct.js';
import DataSheet_SNAct from './DataSheet_SNAct.js';
import firebase from 'firebase';
import firestore from 'firebase/firestore';


class App extends Component {
  constructor(props) {
    super(props);

    this.dataSheets = {};
    this.dataSheets['localizationSheet'] = new DataSheet_localizationSheet('localizationSheet', this.dataSheetDidUpdate);
    this.dataSheets['user'] = new DataSheet_user('user', this.dataSheetDidUpdate);
    this.dataSheets['profile'] = new DataSheet_profile('profile', this.dataSheetDidUpdate);
    this.dataSheets['subscriptions'] = new DataSheet_subscriptions('subscriptions', this.dataSheetDidUpdate);
    this.dataSheets['serialNumbers'] = new DataSheet_serialNumbers('serialNumbers', this.dataSheetDidUpdate);
    this.dataSheets['gender'] = new DataSheet_gender('gender', this.dataSheetDidUpdate);
    this.dataSheets['subAct'] = new DataSheet_subAct('subAct', this.dataSheetDidUpdate);
    this.dataSheets['SNAct'] = new DataSheet_SNAct('SNAct', this.dataSheetDidUpdate);
    this.dataSheetLoaded = {};

    this.dataSlots = {};
    this.dataSlots['ds_activeLang'] = "en";
    this.dataSlots['ds_username'] = "";
    this.dataSlots['ds_userid'] = "";
    this.dataSlots['ds_useremail'] = "";
    this.dataSlots['ds_userphoto'] = "";
    this.dataSlots['ds_userphone'] = "";
    this.dataSlots['ds_activateResult'] = "";
    this.dataSlots['ds_sharecodeResult'] = "";
    this.dataSlots['ds_subscription'] = "";
    this.dataSlots['ds_subscriptionResult'] = "";
    this.dataSlots['ds_license'] = "";
    this.dataSlots['ds_isAdmin'] = "";

    this.updateLocalizationFromDataSheet(this.dataSheets['localizationSheet']);


    // Initialize web service plugin 'firebase_the-stack-482f9'
    firebase.initializeApp({
      apiKey: "AIzaSyDwbru2Tn3EVzhALevWFmlPG4g1yF-ZK7E",
      authDomain: "the-stack-482f9.firebaseapp.com",
      databaseURL: "https://the-stack-482f9.firebaseio.com",
      projectId: "the-stack-482f9",
      storageBucket: "the-stack-482f9.appspot.com",
      messagingSenderId: "188995217107",
      appId: "1:188995217107:web:a2dc1ce13bb3e3ee191626",
      measurementId: "G-NXFGGDL6GD"
    });
    firebase.firestore().settings({});
    
    this.serviceOptions_user = {
      dataSlots: this.dataSlots,
      servicePath: "users/$slot('ds_userid')",
      query: "",
    };
    this.dataSheets['user'].appActions = this;
    this.dataSheets['user'].firebase = firebase;
    
    this.serviceOptions_profile = {
      dataSlots: this.dataSlots,
      servicePath: "/profiles/$slot('ds_userid')",
      query: "",
    };
    this.dataSheets['profile'].appActions = this;
    this.dataSheets['profile'].firebase = firebase;
    
    this.serviceOptions_subscriptions = {
      dataSlots: this.dataSlots,
      servicePath: "subscriptions",
      query: "",
    };
    this.dataSheets['subscriptions'].appActions = this;
    this.dataSheets['subscriptions'].firebase = firebase;
    
    this.serviceOptions_serialNumbers = {
      dataSlots: this.dataSlots,
      servicePath: "serialnumbers",
      query: "",
    };
    this.dataSheets['serialNumbers'].appActions = this;
    this.dataSheets['serialNumbers'].firebase = firebase;
    
    this.serviceOptions_subAct = {
      dataSlots: this.dataSlots,
      servicePath: "subscriptionsActivations",
      query: "",
    };
    this.dataSheets['subAct'].appActions = this;
    this.dataSheets['subAct'].firebase = firebase;
    
    this.serviceOptions_SNAct = {
      dataSlots: this.dataSlots,
      servicePath: "serialnumbersActivations",
      query: "",
    };
    this.dataSheets['SNAct'].appActions = this;
    this.dataSheets['SNAct'].firebase = firebase;
    

    this.state = {
      screenTransitionForward: true,
    }

  }

  windowDidResize = () => {
    let w = window.innerWidth;
    let formatId;
    if (w < 576) formatId = 'narrow-phone';
    else if (w < 768) formatId = 'wide-phone';
    else if (w < 1024) formatId = 'narrow-tablet';
    else formatId = 'wide-tablet';
    if (formatId !== this.state.screenFormatId) {
      this.setState({screenFormatId: formatId});
    }
  }

  componentDidMount() {
    this.windowDidResize();
    window.addEventListener('resize', this.windowDidResize);

    this.serviceOptions_subscriptions.servicePath = this.dataSheets['subscriptions'].expandSlotTemplateString("subscriptions", this.dataSlots);
    this.loadData_firebase_thestack482f9(this.dataSheets['subscriptions'], this.serviceOptions_subscriptions, true);
    
    this.serviceOptions_serialNumbers.servicePath = this.dataSheets['serialNumbers'].expandSlotTemplateString("serialnumbers", this.dataSlots);
    this.loadData_firebase_thestack482f9(this.dataSheets['serialNumbers'], this.serviceOptions_serialNumbers, true);
    
    this.serviceOptions_subAct.servicePath = this.dataSheets['subAct'].expandSlotTemplateString("subscriptionsActivations", this.dataSlots);
    this.loadData_firebase_thestack482f9(this.dataSheets['subAct'], this.serviceOptions_subAct, true);
    
    this.serviceOptions_SNAct.servicePath = this.dataSheets['SNAct'].expandSlotTemplateString("serialnumbersActivations", this.dataSlots);
    this.loadData_firebase_thestack482f9(this.dataSheets['SNAct'], this.serviceOptions_SNAct, true);
    
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.windowDidResize);
  }

  isLoading() {
    return this.state.loading;
  }

  goToScreen = (screenId, props) => {
    // This method is the default implementation and could be customized by a navigation plugin.
    this.props.history.push('/'+screenId, {...props, appActions: null, locStrings: null, dataSheets: null});
    window.scrollTo(0, 0);
  }

  goBack = () => {
    // This method is the default implementation and could be customized by a navigation plugin.
    this.props.history.goBack();
  }

  getDataSheet = (sheetId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    return this.dataSheets[sheetId];
  }

  addToDataSheet = (sheetId, newRow, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && newRow) {
      let promise = sheet.addItem(newRow, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  updateInDataSheet = (sheetId, row, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      let promise = sheet.replaceItemByKey(row.key, row, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  removeFromDataSheet = (sheetId, row) => {
    let sheet = this.dataSheets[sheetId];
    if (sheet && row) {
      let promise = sheet.removeItem(row, this['serviceOptions_'+sheetId] || {});
      this.setState({});
      return promise;
    }
  }

  updateDataSlot = (slotId, value, actionId) => {
    // This method is the default implementation and could be customized by a state management plugin.
    if (value === this.dataSlots[slotId])
      return;

    this.dataSlots[slotId] = value;

    if (slotId === 'ds_activeLang') {
      this.locStrings.setLanguage(value);
    }

    if (slotId === 'ds_userid') {
      this.serviceOptions_user.servicePath = value;
      this.loadData_firebase_thestack482f9(this.dataSheets['user'], this.serviceOptions_user, true);
    }
    if (slotId === 'ds_userid') {
      this.serviceOptions_profile.servicePath = value;
      this.loadData_firebase_thestack482f9(this.dataSheets['profile'], this.serviceOptions_profile, true);
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['subscriptions'].expandSlotTemplateString("subscriptions", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_subscriptions.servicePath = servicePath;
        this.loadData_firebase_thestack482f9(this.dataSheets['subscriptions'], this.serviceOptions_subscriptions, true);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['serialNumbers'].expandSlotTemplateString("serialnumbers", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_serialNumbers.servicePath = servicePath;
        this.loadData_firebase_thestack482f9(this.dataSheets['serialNumbers'], this.serviceOptions_serialNumbers, true);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['subAct'].expandSlotTemplateString("subscriptionsActivations", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_subAct.servicePath = servicePath;
        this.loadData_firebase_thestack482f9(this.dataSheets['subAct'], this.serviceOptions_subAct, true);
      }
    }
    {
      let usedSlots = [];
      let servicePath = this.dataSheets['SNAct'].expandSlotTemplateString("serialnumbersActivations", this.dataSlots, usedSlots);
      if (usedSlots.includes(slotId)) {
        // if data sheet's content depends on this slot, reload it now
        this.serviceOptions_SNAct.servicePath = servicePath;
        this.loadData_firebase_thestack482f9(this.dataSheets['SNAct'], this.serviceOptions_SNAct, true);
      }
    }
    this.setState({});
  }

  dataSheetDidUpdate = (dataSheet) => {
    // This method is the default implementation and could be customized by a state management plugin.
    this.setState({});
  }

  updateLocalizationFromDataSheet = (dataSheet) => {
    const stringsObj = dataSheet.getStringsByLanguage();
    if (stringsObj && Object.keys(stringsObj).length > 0) {
      this.locStrings = new LocalizedStrings(stringsObj);
    } else {
      this.locStrings = new LocalizedStrings({en: {}});
    }
    this.locStrings.setLanguage(this.dataSlots['ds_activeLang']);
  }

  loadData_firebase_thestack482f9 = (dataSheet, options, firstLoad) => {
    // This method was written by data plugin 'Firebase (Cloud Firestore)'.
    this.setState({loading: true});
    
    // clear any placeholder data before load
    if (firstLoad) {
      dataSheet.items = [];
    }
    
    const fetchComplete = (err) => {
      if (err) {
        // This error handling comes from React Studio
        // and currently doesn't do anything useful.
        console.error('** Web service load failed: ', err);
      } else {
      }
      this.setState({loading: false});
    }
    
    const db = firebase.firestore();
    let isCollectionGroup = options.servicePath.startsWith("group:");
    let collection;
    if (isCollectionGroup) {
      collection = db.collectionGroup(options.servicePath.substring(6));
    } else {
      let path = options.servicePath.trim();
      if (path.startsWith("/")) path = path.substring(1);
      if (path.endsWith("/")) path = path.substring(0, path.length-1);
      if ((path.split("/").length-1)%2==0) {
        collection = db.collection(path);
      } else {
        collection = db.doc(path);
      }
    }
    const query = dataSheet.expandSlotTemplateString(options.query, this.dataSlots);
    let queryObj;
    
    if (query.length < 1) {
      queryObj = collection;
    } else {
      console.log("loading firebase data for '%s' with query: %s", options.servicePath, query);
      try {
        queryObj = eval(`(function(c){ return c.${query}; })(collection)`);
      } catch (e) {
        console.log("** error creating firebase query object from '%s': ", query, e)
        return;
      }
    }
    
    queryObj.onSnapshot(
      (querySnapshot) => {
        let jsonArr = [];
        
        if (querySnapshot.docs) {
          querySnapshot.forEach((doc) => {
            const data = { ...doc.data(), document_key: doc.id, document_path: doc.ref.path };
            jsonArr.push(data);
          });
        } else if (querySnapshot.data) {
          const doc = querySnapshot;
          const data = { ...doc.data(), document_key: doc.id, document_path: doc.ref.path };
          jsonArr.push(data);
        }    
            
        dataSheet.loadFromJson(jsonArr);
        fetchComplete(null, options);  
      },
      (err) => {
        fetchComplete(err, options);
      });  
    
    
     /*
    dbLoadingPromise.get().then((querySnapshot) => {
        let jsonArr = [];
    
        querySnapshot.forEach((doc) => {
          const data = { ...doc.data(), key: doc.id };
          jsonArr.push(data);
        });
            
        dataSheet.loadFromJson(jsonArr);
        fetchComplete(null, options);
      },
      (err) => {
        fetchComplete(err, options);
      });  
      */
    
  }

  createImageUrlFromProp = (prop) => {
    if (prop instanceof Object) {
      if (prop.type != null && prop.type === 'image' && prop.path != null) {
        return "(null)"+prop.path;
      }
    }
    return prop;
  }

  render() {
    let makeElementForScreen = (screenId, baseProps, atTop, forward) => {
      let screenProps = {
        ...baseProps,
        atTopOfScreenStack: atTop,
        transitionForward: forward,
        appActions: this,
        dataSheets: this.dataSheets,
        locStrings: this.locStrings,
        deviceInfo: {
          screenFormatId: this.state.screenFormatId
        },
        'ds_activeLang': this.dataSlots['ds_activeLang'],
        'ds_username': this.dataSlots['ds_username'],
        'ds_userid': this.dataSlots['ds_userid'],
        'ds_useremail': this.dataSlots['ds_useremail'],
        'ds_userphoto': this.dataSlots['ds_userphoto'],
        'ds_userphone': this.dataSlots['ds_userphone'],
        'ds_activateResult': this.dataSlots['ds_activateResult'],
        'ds_sharecodeResult': this.dataSlots['ds_sharecodeResult'],
        'ds_subscription': this.dataSlots['ds_subscription'],
        'ds_subscriptionResult': this.dataSlots['ds_subscriptionResult'],
        'ds_license': this.dataSlots['ds_license'],
        'ds_isAdmin': this.dataSlots['ds_isAdmin'],
      };
      // A data sheet row was specified as the data source for this screen, so carry those props + 'dataSheetRow'.
      const dataSheetRow_HomeScreen = this.dataSheets['profile'].items[0];
      const screenData_HomeScreen = {
        ...dataSheetRow_HomeScreen,
        dataSheetRow: dataSheetRow_HomeScreen,
      }
      // A data sheet row was specified as the data source for this screen, so carry those props + 'dataSheetRow'.
      const dataSheetRow_RegistrationScreen = this.dataSheets['user'].items[0];
      const screenData_RegistrationScreen = {
        ...dataSheetRow_RegistrationScreen,
        dataSheetRow: dataSheetRow_RegistrationScreen,
      }
      switch (screenId) {
        default:
          return null;
        case 'home':
          return (<HomeScreen {...screenProps} {...screenData_HomeScreen} />)
        case 'registration':
          return (<RegistrationScreen {...screenProps} {...screenData_RegistrationScreen} />)
        case 'login':
          return (<LoginScreen {...screenProps} />)
        case 'admin':
          return (<AdminScreen {...screenProps} />)
      }
    }

    return (
      <div className="App">
        <Switch>
          <Route path="/" render={(props) => makeElementForScreen('login', props.location.state, true, true)} exact />
          <Route path="/home" render={(props) => {
            return (firebase.auth().currentUser != null) ? makeElementForScreen('home', props.location.state, true, true) : <Redirect to="/login" />;
          }} />
          <Route path="/registration" render={(props) => {
            return (firebase.auth().currentUser != null) ? makeElementForScreen('registration', props.location.state, true, true) : <Redirect to="/login" />;
          }} />
          <Route path="/login" render={(props) => {
            return makeElementForScreen('login', props.location.state, true, true);
          }} />
          <Route path="/admin" render={(props) => {
            return (firebase.auth().currentUser != null) ? makeElementForScreen('admin', props.location.state, true, true) : <Redirect to="/login" />;
          }} />
        </Switch>
      </div>
    );
  }
}
export default withRouter(App)
