import React, { Component } from 'react';
import './App.css';
import btn_icon_527105 from './images/btn_icon_527105.png';
import SerialNumbers2 from './SerialNumbers2';

export default class SerNumEditor extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      serialNumber: '',
      profileLimit: '',
      activationLimit: '',
      orderlabel: (<div>Order</div>),
      orderlabel_plainText: "Order",
      order: '',
      namelabel: (<div>Name</div>),
      namelabel_plainText: "Name",
      name: '',
      elValidator_visible: false,
      validator: (<div> Invalid Value(s)</div>),
      validator_plainText: " Invalid Value(s)",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  textInputChanged_elSerialNumber = (event) => {
    this.setState({serialNumber: event.target.value});
  }
  
  textInputChanged_elProfileLimit = (event) => {
    this.setState({profileLimit: event.target.value});
  }
  
  textInputChanged_elActivationLimit = (event) => {
    this.setState({activationLimit: event.target.value});
  }
  
  textInputChanged_elOrder = (event) => {
    this.setState({order: event.target.value});
  }
  
  textInputChanged_elName = (event) => {
    this.setState({name: event.target.value});
  }
  
  onClick_elButton = async (ev) => {
    // Perform action 'Validate' on element 'validator'
    let transformFunc = function(input, name, value) {
      // This function is executed for each validated element with the following parameters:
      //   input: precalculated value based on the default validation logic (true/false)
      //   name: name of the element/data slot
      //   value: content value of element/data slot
      //
      // Example:
      //   if (name == 'field') {
      //     if (value == 'Hello') return true;
      //   }
      //
      
      if (name == 'ActivationLimit') {
        return /^\+?\d+$/.test(value);
      }
      if (name == 'ProfileLimit') {
        return /^\+?\d+$/.test(value);
      }
      if (name == 'SerialNumber') {
        return /^\+?[A-Z]?[A-Z]?-?P?\d+$/.test(value);
      }
      
      return input;};
    
    const items = [
      { result: this.state.profileLimit != null && this.state.profileLimit.length > 0 && /^\d*$/.test(this.state.profileLimit) /* only numbers */, name: 'ProfileLimit', value: this.state.profileLimit },
      { result: this.state.activationLimit != null && this.state.activationLimit.length > 0 && /^\d*$/.test(this.state.activationLimit) /* only numbers */, name: 'ActivationLimit', value: this.state.activationLimit },
      { result: this.state.serialNumber != null && this.state.serialNumber.length > 0 && true /* validate this.state.serialNumber */, name: 'SerialNumber', value: this.state.serialNumber },
    ];
    
    let valid = true;
    for (let i = 0; i < items.length; ++i) {
      let item = items[i];
      valid &= transformFunc(item.result, item.name, item.value);
      if ( !valid) {
        break;
      }
    };
    this.setState({elValidator_visible: !valid});
    
    if ( !valid) {
      return;
    }
  
    await this.sendData_elButton_to_serialNumbers();
  
    // Clear internal state for data-providing elements in this component
    this.setState({
      text: (<div></div>),
      text_plainText: "",
      text2: (<div></div>),
      text2_plainText: "",
      textCopy: (<div></div>),
      textCopy_plainText: "",
      textCopy2: (<div></div>),
      textCopy2_plainText: "",
      serialNumber: "",
      profileLimit: "",
      activationLimit: "",
      orderlabel: (<div></div>),
      orderlabel_plainText: "",
      order: "",
      namelabel: (<div></div>),
      namelabel_plainText: "",
      name: "",
      validator: (<div></div>),
      validator_plainText: "",
    });
  
  }
  
  
  sendData_elButton_to_serialNumbers = () => {
    const dataSheet = this.props.appActions.getDataSheet('serialNumbers');
  
    let row = this.props.dataSheetRow || {
    };
    row = { ...row, 
      activationLimit: this.state.activationLimit,
      document_key: this.state.serialNumber,
      profileLimit: this.state.profileLimit,
      order: this.state.order,
      ordername: this.state.name,
    };
    if (this.props.dataSheetId === dataSheet.id) {
      return this.props.appActions.updateInDataSheet('serialNumbers', row);
    } else {
      return this.props.appActions.addToDataSheet('serialNumbers', row);
    }
  }
  
  
  render() {
    // eslint-disable-next-line
    const dataSheet_serialNumbers = this.props.appActions.getDataSheet('serialNumbers');
    const style_elText = {
      color: '#212121',
      textAlign: 'center',
     };
    const style_elCard_outer = {
      backgroundColor: 'white',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    const style_elText2 = {
      fontSize: 9.5,
      color: '#212121',
      textAlign: 'center',
     };
    const style_elTextCopy = {
      fontSize: 9.5,
      color: '#212121',
      textAlign: 'center',
     };
    const style_elTextCopy2 = {
      fontSize: 9.5,
      color: '#212121',
      textAlign: 'center',
     };
    
    const style_elSerialNumber = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: '#212121',
      fontSize: 11.4,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    
    const style_elProfileLimit = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: '#212121',
      fontSize: 11.4,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    
    const style_elActivationLimit = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: '#212121',
      fontSize: 11.4,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const style_elOrderLabel = {
      fontSize: 9.5,
      color: '#212121',
      textAlign: 'center',
     };
    
    const style_elOrder = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: '#212121',
      fontSize: 11.4,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    const style_elNameLabel = {
      fontSize: 9.5,
      color: '#212121',
      textAlign: 'center',
     };
    
    const style_elName = {
      display: 'block',
      backgroundColor: 'white',
      paddingLeft: '1rem',
      boxSizing: 'border-box', // ensures padding won't expand element's outer size
      color: '#212121',
      fontSize: 11.4,
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    
    const style_elButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_527105+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '101.667%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elValidator = {
      color: '#1436ff',
      textAlign: 'center',
     };
    const elValidator = this.state.elValidator_visible ? (
      <div className="elValidator">
        <div className="baseFont" style={style_elValidator}>
          <div>{this.state.validator}</div>
        </div>
      </div>
      
     ) : null;
    
    // Source items and any special components used for list/grid element 'list'.
    let items_list = [];
    let listComps_list = {};
    items_list = items_list.concat(this.props.appActions.getDataSheet('serialNumbers').items);
    this._elList_items = [];
    
    const style_elList = {
      height: 'auto',  // This element is in scroll flow
     };
    
    return (
      <div className="SerNumEditor">
        <div className="layoutFlow">
          <div className="elText">
            <div className="headlineFont" style={style_elText}>
              <div>{this.props.locStrings.sernumheader_title}</div>
            </div>
          </div>
          
          <div className="elCard" style={style_elCard_outer}>
            <div className="cardBg" />
          </div>
          
          <div className="flowRow flowRow_SerNumEditor_elText2_768484">
          <div className="elText2">
            <div className="systemFontRegular" style={style_elText2}>
              <div>{this.props.locStrings.sernumheader_profiles}</div>
            </div>
          </div>
          
          <div className="elTextCopy">
            <div className="systemFontRegular" style={style_elTextCopy}>
              <div>{this.props.locStrings.sernumheader_activations}</div>
            </div>
          </div>
          
          <div className="elTextCopy2">
            <div className="systemFontRegular" style={style_elTextCopy2}>
              <div>{this.props.locStrings.sernumheader_sernum}</div>
            </div>
          </div>
          
          <div className="elSerialNumber">
            <input className="systemFontRegular" style={style_elSerialNumber} type="text" placeholder={this.props.locStrings.sernumheader_sernumph} onChange={this.textInputChanged_elSerialNumber} value={this.state.serialNumber}  />
          </div>
          
          <div className="elProfileLimit">
            <input className="systemFontRegular" style={style_elProfileLimit} type="number" placeholder={this.props.locStrings.subscriptheader_profileph} onChange={this.textInputChanged_elProfileLimit} value={this.state.profileLimit}  />
          </div>
          
          <div className="elActivationLimit">
            <input className="systemFontRegular" style={style_elActivationLimit} type="number" placeholder={this.props.locStrings.sernumheader_activationsph} onChange={this.textInputChanged_elActivationLimit} value={this.state.activationLimit}  />
          </div>
          
          <div className="elOrderLabel">
            <div className="systemFontRegular" style={style_elOrderLabel}>
              <div>{this.state.orderlabel}</div>
            </div>
          </div>
          
          <div className="elOrder">
            <input className="systemFontRegular" style={style_elOrder} type="text" placeholder="" onChange={this.textInputChanged_elOrder} value={this.state.order}  />
          </div>
          
          <div className="elNameLabel">
            <div className="systemFontRegular" style={style_elNameLabel}>
              <div>{this.state.namelabel}</div>
            </div>
          </div>
          
          <div className="elName">
            <input className="systemFontRegular" style={style_elName} type="text" placeholder="" onChange={this.textInputChanged_elName} value={this.state.name}  />
          </div>
          
          <div className="elButton">
            <button className="actionFont" style={style_elButton} onClick={this.onClick_elButton}  />
          </div>
          
          </div>
          { elValidator }
          <div className="hasNestedComps elList">
            <ul style={style_elList}>
              {items_list.map((row, index) => {
                let itemComp = (row._componentId)
                    ? listComps_list[row._componentId]
                    : <SerialNumbers2 appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} dataSheetId={'serialNumbers'} dataSheetRow={row} {...{ 'document_key': row['document_key'], 'profileLimit': row['profileLimit'], 'activationLimit': row['activationLimit'], 'order': row['order'], 'ordername': row['ordername'], }} ref={(el) => {if (el) this._elList_items.push(el)}} />;
                return (<li key={row.key}>
                    {itemComp}
                  </li>);
              })}
              <div className="marker" ref={(el)=> this._elList_endMarker = el} />
            </ul>
          </div>
        </div>
        
      </div>
    )
  }
  
}
