import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/TheStackApp_elImage_457590.png';

export default class TheStackApp extends Component {

  // This component doesn't use any properties

  constructor(props) {
    super(props);
    
    this.state = {
      thestacklicense: (<div>Download TheStack App</div>),
      thestacklicense_plainText: "Download TheStack App",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elImage = async (ev) => {
    window.open('https://apps.apple.com/us/app/the-stack-system/id1534757407?itsct=apps_box&itscg=30200', '_blank');
  
  }
  
  
  render() {
    const style_elTheStackLicense = {
      color: '#212121',
      textAlign: 'center',
     };
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="TheStackApp appBg">
        <div className="layoutFlow">
          <div className="elTheStackLicense">
            <div className="baseFont" style={style_elTheStackLicense}>
              <div>{this.state.thestacklicense}</div>
            </div>
          </div>
          
          <div className="elImage">
            <div style={style_elImage} onClick={this.onClick_elImage}  />
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
        </div>
        
      </div>
    )
  }
  
}
