import React, { Component } from 'react';
import './App.css';
import btn_icon_235390 from './images/btn_icon_235390.png';

export default class Subscriptions3 extends Component {

  // Properties used by this component:
  // document_key, profileLimit, activationLimit, order, ordername

  constructor(props) {
    super(props);
    
    this.state = {
      subscription: (<div>REZ6X8NTibezfUScLeG8Tkt9Trv2</div>),
      subscription_plainText: "REZ6X8NTibezfUScLeG8Tkt9Trv2",
      profilelimit: (<div>Profile Limit: </div>),
      profilelimit_plainText: "Profile Limit: ",
      activationlimit: (<div>Activation Limit: </div>),
      activationlimit_plainText: "Activation Limit: ",
      order: (<div>Order: </div>),
      order_plainText: "Order: ",
      name: (<div>Name: </div>),
      name_plainText: "Name: ",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elFab = async (ev) => {
    // Remove row from connected sheet
    await this.props.appActions.removeFromDataSheet(this.props.dataSheetId, this.props.dataSheetRow);
  
  }
  
  
  render() {
    const value_subscription = this.props.document_key;
    
    const style_elSubscription = {
      color: '#212121',
      textAlign: 'left',
     };
    let transformPropValue_profileLimit = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return 'Profile Limit: ' + input;
    }
    
    const value_profileLimit = transformPropValue_profileLimit(this.props.profileLimit);
    
    const style_elProfileLimit = {
      color: '#212121',
      textAlign: 'left',
     };
    let transformPropValue_activationLimit = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return 'Activation Limit: ' + input;
    }
    
    const value_activationLimit = transformPropValue_activationLimit(this.props.activationLimit);
    
    const style_elActivationLimit = {
      color: '#212121',
      textAlign: 'left',
     };
    let transformPropValue_order = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return 'Order: ' + input;
    }
    
    const value_order = transformPropValue_order(this.props.order);
    
    const style_elOrder = {
      fontSize: 11.4,
      color: '#212121',
      textAlign: 'left',
     };
    let transformPropValue_name = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return 'Name: ' + input;
    }
    
    const value_name = transformPropValue_name(this.props.ordername);
    
    const style_elName = {
      fontSize: 11.4,
      color: '#212121',
      textAlign: 'left',
     };
    const style_elCard_outer = {
      backgroundColor: '#fec10d',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    
    const style_elFab = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_235390+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '100%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    return (
      <div className="Subscriptions3">
        <div className="layoutFlow">
          <div className="elSubscription">
            <div className="headlineFont" style={style_elSubscription}>
              <div>{value_subscription}</div>
            </div>
          </div>
          
          <div className="elProfileLimit">
            <div className="baseFont" style={style_elProfileLimit}>
              <div>{value_profileLimit}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_Subscriptions3_elActivationLimit_632570">
          <div className="elActivationLimit">
            <div className="baseFont" style={style_elActivationLimit}>
              <div>{value_activationLimit}</div>
            </div>
          </div>
          
          </div>
          <div className="elOrder">
            <div className="systemFontRegular" style={style_elOrder}>
              <div>{value_order}</div>
            </div>
          </div>
          
          <div className="flowRow flowRow_Subscriptions3_elName_45612">
          <div className="elName">
            <div className="systemFontRegular" style={style_elName}>
              <div>{value_name}</div>
            </div>
          </div>
          
          </div>
          <div className="elCard" style={style_elCard_outer}>
            <div className="highlightBg" />
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont elFab" style={style_elFab} onClick={this.onClick_elFab}  />
        </div>
      </div>
    )
  }
  
}
