import React, { Component } from 'react';
import './App.css';
import img_elImage from './images/GenerateSharecode_elImage_248028.png';
import btn_icon_914351 from './images/btn_icon_914351.png';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class GenerateSharecode extends Component {

  // Properties used by this component:
  // sharecode, sharecodeLicense

  constructor(props) {
    super(props);
    
    this.state = {
      licenseprompt: (<div>I want to connect with my coach</div>),
      licenseprompt_plainText: "I want to connect with my coach",
      sharecodelicense: (<div> </div>),
      sharecodelicense_plainText: " ",
      sharecode: (<div> </div>),
      sharecode_plainText: " ",
      sharecoderesult: (<div> </div>),
      sharecoderesult_plainText: " ",
      process: (<div>Generate a share-code and provide it to your coach. Once you are added to their stable, you will be able to log in to TheStack App and begin your training!</div>),
      process_plainText: "Generate a share-code and provide it to your coach. Once you are added to their stable, you will be able to log in to TheStack App and begin your training!",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elGenSharecode = async (ev) => {
    //import firebase from 'firebase';
    const firebase = require("firebase")
    require("firebase/functions");
    
    this.props.appActions.setState({loading: true});
    
    var device = navigator.userAgent
    var name = this.props.appActions.dataSlots['ds_username']
    if (!name || name==='')
    {
      name = 'Anonymous';
    }
    
    const callableReturnMessage = firebase.functions().httpsCallable('ensureSharecode');
    callableReturnMessage({
      "device": device
      ,"name": name
      ,"type": "full"
    }).then((result) => {
      this.props.appActions.updateDataSlot('ds_sharecodeResult', `Successfully retrieved your sharecode.`);
      this.props.appActions.updateDataSlot('ds_userid', this.props.appActions.dataSlots['ds_userid']);
      console.log(result.data);
      this.props.appActions.setState({loading: false});
    }).catch((error) => {
      this.props.appActions.updateDataSlot('ds_sharecodeResult', `error: ${JSON.stringify(error)}`);
      console.log(`error: ${JSON.stringify(error)}`);
      this.props.appActions.setState({loading: false});
    });
    
  
  }
  
  
  onClick_elHelp = async (ev) => {
    window.open('https://www.thestacksystem.com/apps/help-center#hc-i-dont-own-the-stack-hardware-but-my-coach-asked-me-to-start-training-what-do-i-do', '_blank');
  
  }
  
  
  render() {
    const style_elLicensePrompt = {
      color: '#212121',
      textAlign: 'center',
     };
    const style_elImage = {
      backgroundImage: 'url('+img_elImage+')',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: '50% 50%',
      backgroundSize: 'cover',
     };
    let transformPropValue_sharecodeLicense = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      if (!input || input==='') {
        return ' ';
      }
      return 'Coach\'s Stable: ' + input;
    }
    
    const value_sharecodeLicense = transformPropValue_sharecodeLicense(this.props.sharecodeLicense);
    
    const style_elSharecodeLicense = {
      color: '#1436ff',
      textAlign: 'center',
     };
    let transformPropValue_sharecode = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      if (!input || input==='') {
        return '';
      }
      
      let user = this.props.appActions.getDataSheet('user')
      if (user.sharecodeLicense && user.sharecodeLicense!=='') {
        return '';
      }
      
      return 'Your sharecode: ' + input;
    }
    
    const value_sharecode = transformPropValue_sharecode(this.props.sharecode);
    
    const style_elSharecode = {
      color: '#1436ff',
      textAlign: 'center',
     };
    let transformPropValue_genSharecode = (input) => {
      // This function modifies the value for property 'enabled'.
      // There is a variable named 'input' that provides the property value.
      // The function must return 'true' (with apostrophes) for enabled value.
      //
      return (!input || input==='');
    }
    
    const enabledValue_genSharecode = transformPropValue_genSharecode(this.props.sharecode);
    
    const style_elGenSharecode = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const value_sharecodeResult = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_sharecodeResult'] : '');
    
    const style_elSharecodeResult = {
      color: '#1436ff',
      textAlign: 'center',
     };
    const style_elProcess = {
      fontSize: 12.3,
      color: '#515151',
      textAlign: 'center',
     };
    
    const style_elHelp = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_914351+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '8.584%',
      paddingLeft: '9%',
      backgroundPosition: '0% 50%',
      color: '#515151',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elCard_outer = {
      backgroundColor: '#fec10d',
      filter: 'drop-shadow(0.0px 2.3px 18px rgba(0, 0, 0, 0.1600))',
      overflow: 'visible',
     };
    
    return (
      <div className="GenerateSharecode appBg">
        <div className="layoutFlow">
          <div className="elLicensePrompt">
            <div className="headlineFont" style={style_elLicensePrompt}>
              <div>{this.state.licenseprompt}</div>
            </div>
          </div>
          
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="flowRow flowRow_GenerateSharecode_elImage_248028">
          <div className="elImage">
            <div style={style_elImage} />
          </div>
          
          <div className="elSharecodeLicense">
            <div className="baseFont" style={style_elSharecodeLicense}>
              <div>{value_sharecodeLicense}</div>
            </div>
          </div>
          
          <div className="elSharecode">
            <div className="baseFont" style={style_elSharecode}>
              <div>{value_sharecode}</div>
            </div>
          </div>
          
          <div className="elGenSharecode">
            <Button className="actionFont" style={style_elGenSharecode}  color="accent" disabled={''+enabledValue_genSharecode !== 'true'} onClick={this.onClick_elGenSharecode} >
              Get my Sharecode
            </Button>
          </div>
          
          </div>
          <div className="elSharecodeResult">
            <div className="baseFont" style={style_elSharecodeResult}>
              <div>{value_sharecodeResult}</div>
            </div>
          </div>
          
          <div className="elProcess">
            <div className="systemFontRegular" style={style_elProcess}>
              <div>{this.state.process}</div>
            </div>
          </div>
          
          <div className="elHelp">
            <button className="actionFont" style={style_elHelp} onClick={this.onClick_elHelp} >
              More About Sharecodes
            </button>
          </div>
          
          <div className="elCard" style={style_elCard_outer}>
            <div className="highlightBg" />
          </div>
        </div>
        
      </div>
    )
  }
  
}
