import React, { Component } from 'react';
import './App.css';
import btn_icon_835422 from './images/btn_icon_835422.png';
import img_elTheStack from './images/HomeScreen_elTheStack_664718.png';
import img_elLogo from './images/HomeScreen_elLogo_816790.png';
import Menu from './Menu';

// UI framework component imports
import Select from 'muicss/lib/react/select';
import Option from 'muicss/lib/react/option';
import Button from 'muicss/lib/react/button';
import Appbar from 'muicss/lib/react/appbar';

export default class HomeScreen extends Component {

  // Properties used by this component:
  // appActions, deviceInfo, name, birthYear, gender, golfDexterity, throwingHand, currentDrivingDistance, handicap, currentDriverLength, ageGroup, finishedPrograms

  constructor(props) {
    super(props);
    
    this.state = {
      username: (<div>GOLFER</div>),
      username_plainText: "GOLFER",
      picker: this.props.gender,
      birthyear: (<div>birthYear=1980</div>),
      birthyear_plainText: "birthYear=1980",
      golfdexterity: (<div>golfDexterity=Right</div>),
      golfdexterity_plainText: "golfDexterity=Right",
      throwinghand: (<div>throwingHand=Right</div>),
      throwinghand_plainText: "throwingHand=Right",
      currentdrivingdistance: (<div>currentDrivingDistance=250</div>),
      currentdrivingdistance_plainText: "currentDrivingDistance=250",
      handicap: (<div>handicap=20</div>),
      handicap_plainText: "handicap=20",
      currentdriverlength: (<div>currentDriverLength=44.5</div>),
      currentdriverlength_plainText: "currentDriverLength=44.5",
      thestacklength: (<div>theStackLength=Adult</div>),
      thestacklength_plainText: "theStackLength=Adult",
      finishedprograms: (<div>finishedPrograms=</div>),
      finishedprograms_plainText: "finishedPrograms=",
      menu_isOpen: false,
    };
  }

  componentDidMount() {
    {
      let dataSheet = this.props.appActions.dataSheets['profile'];
      let serviceOptions = this.props.appActions.serviceOptions_profile;
      if ( !this.props.appActions.dataSheetLoaded['profile']) {
        serviceOptions.servicePath = dataSheet.expandSlotTemplateString("/profiles/$slot('ds_userid')", this.props.appActions.dataSlots);
        this.props.appActions.loadData_firebase_thestack482f9(dataSheet, serviceOptions, true);
        this.props.appActions.dataSheetLoaded['profile'] = true;
      }
    }
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  pickerValueChanged_elPicker = (event) => {
    this.setState({picker: event.target.value});
  }
  
  onClick_elMenuButton = async (ev) => {
    this.toggle_menu();
  
  }
  
  
  toggle_menu = () => {
    this.setState({menu_isOpen: !this.state.menu_isOpen});
  }
  
  render() {
    let layoutFlowStyle = {};
    let baseStyle = {};
    if (this.props.transitionId && this.props.transitionId.length > 0 && this.props.atTopOfScreenStack && this.props.transitionForward) {
      baseStyle.animation = '0.25s ease-in-out '+this.props.transitionId;
    }
    if ( !this.props.atTopOfScreenStack) {
      layoutFlowStyle.height = '100vh';
      layoutFlowStyle.overflow = 'hidden';
    }
    
    const style_elBackground = {
      width: '100%',
      height: '100%',
     };
    const style_elBackground_outer = {
      backgroundColor: '#f6f6f6',
     };
    let transformPropValue_username = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return input;
    }
    
    const value_username = transformPropValue_username(this.props.name);
    
    const style_elUsername = {
      color: '#212121',
      textAlign: 'center',
     };
    const style_elGenderLabel = {
      color: '#515151',
      textAlign: 'right',
     };
    
    let selection_picker = this.state.picker;
    // Source datasheet and selected data column for picker element 'picker'
    const dataSource_picker = this.props.appActions.getDataSheet('gender');
    const valueColumnName_picker = 'Value';
    const labelColumnName_picker = 'Name';
    
    const style_elPicker = {
      pointerEvents: 'auto',
     };
    let transformPropValue_birthYear = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return 'birthYear=' + input;
    }
    
    const value_birthYear = transformPropValue_birthYear(this.props.birthYear);
    
    const style_elBirthYear = {
      fontSize: 12.3,
      color: '#515151',
      textAlign: 'center',
     };
    let transformPropValue_golfDexterity = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return 'golfDexterity=' + input;
    }
    
    const value_golfDexterity = transformPropValue_golfDexterity(this.props.golfDexterity);
    
    const style_elGolfDexterity = {
      fontSize: 12.3,
      color: '#515151',
      textAlign: 'center',
     };
    let transformPropValue_throwingHand = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return 'throwingHand=' + input;
    }
    
    const value_throwingHand = transformPropValue_throwingHand(this.props.throwingHand);
    
    const style_elThrowingHand = {
      fontSize: 12.3,
      color: '#515151',
      textAlign: 'center',
     };
    let transformPropValue_currentDrivingDistance = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return 'currentDrivingDistance=' + input;
    }
    
    const value_currentDrivingDistance = transformPropValue_currentDrivingDistance(this.props.currentDrivingDistance);
    
    const style_elCurrentDrivingDistance = {
      fontSize: 12.3,
      color: '#515151',
      textAlign: 'center',
     };
    let transformPropValue_handicap = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return 'handicap=' + input;
    }
    
    const value_handicap = transformPropValue_handicap(this.props.handicap);
    
    const style_elHandicap = {
      fontSize: 12.3,
      color: '#515151',
      textAlign: 'center',
     };
    let transformPropValue_currentDriverLength = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return 'currentDriverLength=' + input;
    }
    
    const value_currentDriverLength = transformPropValue_currentDriverLength(this.props.currentDriverLength);
    
    const style_elCurrentDriverLength = {
      fontSize: 12.3,
      color: '#515151',
      textAlign: 'center',
     };
    let transformPropValue_theStackLength = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      return 'theStackLength=' + input;
    }
    
    const value_theStackLength = transformPropValue_theStackLength(this.props.ageGroup);
    
    const style_elTheStackLength = {
      fontSize: 12.3,
      color: '#515151',
      textAlign: 'center',
     };
    let transformPropValue_finishedPrograms = (input) => {
      // This function modifies the value for property 'text'.
      // There is a variable named 'input' that provides the property value.
      //
      if (!input || input==='') {
        return 'finishedPrograms=';
      }
      return 'finishedPrograms=' + input.length
    }
    
    const value_finishedPrograms = transformPropValue_finishedPrograms(this.props.finishedPrograms);
    
    const style_elFinishedPrograms = {
      fontSize: 12.3,
      color: '#515151',
      textAlign: 'center',
     };
    
    const style_elButton = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
     };
    
    const style_elMenuButton = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundImage: 'url('+btn_icon_835422+')',
      backgroundRepeat: 'no-repeat',
      backgroundSize: '89.562%',
      backgroundPosition: '50% 0%',
      color: '(null)',
      textAlign: 'left',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elTheStack = {
      height: 'auto',
     };
    const style_elLogo = {
      height: 'auto',
     };
    
    let cssClass_progress = 'circularProgressIndicator';
    if (this.props.appActions.isLoading()) 
      cssClass_progress += ' circularProgressIndicator-active';
    
    const style_menu = {
      pointerEvents: (this.state.menu_isOpen) ? 'auto' : 'none'
    }
    
    return (
      <div className="AppScreen HomeScreen" style={baseStyle}>
        <div className="background">
          <div className="containerMinHeight elBackground" style={style_elBackground_outer}>
            <div className="appBg" style={style_elBackground} />
          </div>
        </div>
        
        <div className="layoutFlow" style={layoutFlowStyle}>
          <div className="elSpacer">
            <div />
          </div>
          
          <div className="elUsername">
            <div className="headlineFont" style={style_elUsername}>
              <div>{value_username}</div>
            </div>
          </div>
          
          <div className="elGenderLabel">
            <div className="baseFont" style={style_elGenderLabel}>
              <div>{this.props.locStrings.home_gender}</div>
            </div>
          </div>
          
          <div className="elPicker">
            <Select className="baseFont" style={style_elPicker}  onChange={this.pickerValueChanged_elPicker} value={selection_picker} >
              {dataSource_picker.items.every(item => {
                return item[valueColumnName_picker] !== selection_picker;
              }) ? <Option value=''/> : null}
              {dataSource_picker.items.map(item => {
                const colValue = item[valueColumnName_picker];
                const labelColValue = item[labelColumnName_picker];
                return <Option key={item.key} value={colValue} label={labelColValue} />
              })}
            </Select>
          </div>
          
          <div className="elBirthYear">
            <div className="systemFontRegular" style={style_elBirthYear}>
              <div>{value_birthYear}</div>
            </div>
          </div>
          
          <div className="elGolfDexterity">
            <div className="systemFontRegular" style={style_elGolfDexterity}>
              <div>{value_golfDexterity}</div>
            </div>
          </div>
          
          <div className="elThrowingHand">
            <div className="systemFontRegular" style={style_elThrowingHand}>
              <div>{value_throwingHand}</div>
            </div>
          </div>
          
          <div className="elCurrentDrivingDistance">
            <div className="systemFontRegular" style={style_elCurrentDrivingDistance}>
              <div>{value_currentDrivingDistance}</div>
            </div>
          </div>
          
          <div className="elHandicap">
            <div className="systemFontRegular" style={style_elHandicap}>
              <div>{value_handicap}</div>
            </div>
          </div>
          
          <div className="elCurrentDriverLength">
            <div className="systemFontRegular" style={style_elCurrentDriverLength}>
              <div>{value_currentDriverLength}</div>
            </div>
          </div>
          
          <div className="elTheStackLength">
            <div className="systemFontRegular" style={style_elTheStackLength}>
              <div>{value_theStackLength}</div>
            </div>
          </div>
          
          <div className="elFinishedPrograms">
            <div className="systemFontRegular" style={style_elFinishedPrograms}>
              <div>{value_finishedPrograms}</div>
            </div>
          </div>
          
          <div className="elButton">
            <Button className="actionFont" style={style_elButton}  color="accent" >
              New button
            </Button>
          </div>
        </div>
        <Appbar className="navBar">
        </Appbar>
        
        <div className="screenFgContainer">
          <div className="foreground">
            <button className="actionFont elMenuButton" style={style_elMenuButton} onClick={this.onClick_elMenuButton}  />
            <img className="elTheStack" style={style_elTheStack} src={img_elTheStack} alt=""  />
            <img className="elLogo" style={style_elLogo} src={img_elLogo} alt=""  />
            <div className="elProgress">
              <svg className={cssClass_progress}>
                <circle className="circularProgress-animatedPath" style={{stroke: '#fec10d'}} cx="25" cy="25" r="17" fill="none" strokeWidth="3" strokeMiterlimit="10" />
              </svg>
            </div>
          </div>
        </div>
        <div className="drawer menu" style={style_menu} onClick={this.toggle_menu}>
          <div className={"drawerContent" + (this.state.menu_isOpen ? " drawerOpen": " drawerClosedLeft")} onClick={(e) => {e.stopPropagation();}}>
            <Menu appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} closeDrawer={this.toggle_menu} />
          </div>
        </div>
      </div>
    )
  }
  
}
