import React, { Component } from 'react';
import './App.css';
import btn_icon_927637 from './images/btn_icon_927637.png';
import Name from './Name';
import firebase from 'firebase';
import firestore from 'firebase/firestore';

// UI framework component imports
import Button from 'muicss/lib/react/button';

export default class Menu extends Component {

  // Properties used by this component:
  // name

  constructor(props) {
    super(props);
    
    this.state = {
      copyright: (<div>Copyright (C) 2021 The Stack System, LP</div>),
      copyright_plainText: "Copyright (C) 2021 The Stack System, LP",
      uid: (<div> </div>),
      uid_plainText: " ",
    };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  componentDidUpdate() {
  }

  onClick_elClose = async (ev) => {
    this.props.closeDrawer();
  
  }
  
  
  onClick_elHome = async (ev) => {
    // Go to screen 'Home'
    this.props.appActions.goToScreen('home', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elRegistration = async (ev) => {
    // Go to screen 'Registration'
    this.props.appActions.goToScreen('registration', { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elLogout = async (ev) => {
    // 'Sign out (lock gate)' action.
    this.clearSavedLogin();
    sessionStorage.setItem('loginStatus_login', 'inactive');
    this.props.appActions.goToScreen('login');
  
  }
  
  
  onClick_elTerms = async (ev) => {
    window.open('https://www.thestacksystem.com/pages/terms-of-use', '_blank');
  
  }
  
  
  onClick_elAdmin = async (ev) => {
    // Go to screen based on value in data slot 'ds_isAdmin'
    this.props.appActions.goToScreen(this.targetTable_adminSwitch(this.transformTargetScreenValue_adminSwitch((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_isAdmin'] : ''))), { transitionId: 'fadeIn' });
  
  }
  
  
  onClick_elPrivacy = async (ev) => {
    window.open('https://www.thestacksystem.com/pages/privacy-policy', '_blank');
  
  }
  
  
  clearSavedLogin() {
    // Logout
    firebase.auth().signOut();
  }
  
  
  targetTable_adminSwitch = (targetId) => {
    if (targetId === 'true')
      return 'admin';
    else if (targetId === 'false')
      return 'home';
  }
  
  transformTargetScreenValue_adminSwitch = (input) => {
    // This function modifies the target screen value of switch 'AdminSwitch'.
    // There is a variable named 'input' that provides the target screen's name.
    // The function may implement custom logic to return a screen name dynamically based on the input value.
    //
    
    if (input === true) {
      console.log('returning true');
      return 'true';
    }
    return 'false';
  }
  
  
  render() {
    // eslint-disable-next-line
    const dataSheet_profile = this.props.appActions.getDataSheet('profile');
    
    const style_elClose = {
      display: 'block',
      color: '(null)',
      textAlign: 'left',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elHome = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elRegistration = {
      display: 'block',
      color: 'white',
      textAlign: 'center',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elLogout = {
      display: 'block',
      textTransform: 'uppercase',
      color: '#1436ff',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elTerms = {
      display: 'block',
      textTransform: 'uppercase',
      color: '#1436ff',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elAdmin = {
      display: 'block',
      textTransform: 'uppercase',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    
    const style_elPrivacy = {
      display: 'block',
      textTransform: 'uppercase',
      color: '#1436ff',
      textAlign: 'center',
      backgroundColor: 'transparent',
      cursor: 'pointer',
      pointerEvents: 'auto',
     };
    const style_elCopyright = {
      fontSize: 8.5,
      color: '#212121',
      textAlign: 'center',
     };
    // eslint-disable-next-line no-unused-vars
    const style_elCopyright_inner = {
      textAlign: 'center',
     };
    
    const value_uid = (this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_userid'] : '');
    
    const style_elUid = {
      fontSize: 7.6,
      color: '#ff8500',
      textAlign: 'center',
      pointerEvents: 'auto',
     };
    // eslint-disable-next-line no-unused-vars
    const style_elUid_inner = {
      textAlign: 'center',
     };
    const style_elFooterLine = {
      width: '100%',
      height: '100%',
     };
    const style_elFooterLine_outer = {
      backgroundColor: '#fec10d',
     };
    
    return (
      <div className="Menu primaryBg">
        <div className="layoutFlow">
          <div className="elClose">
            <Button className="actionFont" style={style_elClose}  variant="fab" color="accent" onClick={this.onClick_elClose} >
              <img src={btn_icon_927637} alt="" style={{width: '100%', marginTop: '4%'}} />
            </Button>
          </div>
          
          <div className="elHome">
            <Button className="actionFont" style={style_elHome}  color="accent" onClick={this.onClick_elHome} >
              Home
            </Button>
          </div>
          
          <div className="elRegistration">
            <Button className="actionFont" style={style_elRegistration}  color="accent" onClick={this.onClick_elRegistration} >
              Registration
            </Button>
          </div>
        </div>
        
        <div className="foreground">
          <button className="actionFont elLogout" style={style_elLogout} onClick={this.onClick_elLogout} >
            Log Out
          </button>
          <button className="actionFont elTerms" style={style_elTerms} onClick={this.onClick_elTerms} >
            Terms of Use
          </button>
          <div className="actionFont elAdmin" style={style_elAdmin} onClick={this.onClick_elAdmin}  />
          <button className="actionFont elPrivacy" style={style_elPrivacy} onClick={this.onClick_elPrivacy} >
            Privacy Policy
          </button>
          <div className="systemFontRegular  elCopyright" style={style_elCopyright}>
            <div className="bottomAlignedContent">{this.state.copyright}</div>
          </div>
          <div className="systemFontRegular  elUid" style={style_elUid}>
            <div className="bottomAlignedContent"><div dangerouslySetInnerHTML={{__html: value_uid}}></div></div>
          </div>
          <div className="elFooterLine" style={style_elFooterLine_outer}>
            <div className="highlightBg" style={style_elFooterLine} />
          </div>
          
          <div className="hasNestedComps elName">
            <Name {...dataSheet_profile.items[0]} ref={(el)=> this._elName = el} appActions={this.props.appActions} deviceInfo={this.props.deviceInfo} locStrings={this.props.locStrings} />
          </div>
        </div>
      </div>
    )
  }
  
}
