// eslint-disable-next-line
import React from 'react';
import DataSheetBase from './DataSheetBase.js';

export default class DataSheet_user extends DataSheetBase {

  constructor(id, updateCb) {
    super(id, updateCb);
    this.requestedKeyPath = "";  // this value can be specified in the React Studio data sheet UI
  }

  makeDefaultItems() {
    // eslint-disable-next-line no-unused-vars
    let key = 1;
    // eslint-disable-next-line no-unused-vars
    let item;

    // The contents of this data sheet will be loaded by plugin 'Firebase (Cloud Firestore)'.
    
    item = {};
    this.items.push(item);
    item['document_key'] = "REZ6X8NTibezfUScLeG8Tkt9Trv2";
    item['activeProfileId'] = "REZ6X8NTibezfUScLeG8Tkt9Trv2";
    item['fcmTokens'] = ["Simulator (iPhone13,4)-|-dY75rWB1Pk8Ioy-bw89X9a:APA91bGH86MO5FXusPXK58Io57AqgL-E98vK1D9ziP1ePkUcX5I8BnV1Z5IS5PxQChfyDyh4v20c-fgI5L-WnAkfKMIkNpkGSu04H6scB-mh-6PCnKl2jugbw2XNnVkNveAAogHDwQBv"];
    item['receiveNotifications'] = "0";
    item['acceptedDisclaimer'] = "1";
    item['name'] = "Livia Cottam";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fcmTokens'] = ["Simulator (iPhone 8)-|-d6GE7sg7Uk8Zogcl3WRNMy:APA91bEsRPaGP-e2ZVRQ_a-kRSgjVoQUF-FDHzogBV_TGAExK8ozxnZUzSiqYrn8FPtno1d3EtxLxbFrgjWygwQejvdh0wF8pPZc4WnfaJkwZ5qnEwITMEigRPVufqZek6DDVmN2xWQS"];
    item['acceptedDisclaimer'] = "1";
    item['subscriptionLicense'] = "123456789";
    item['activationLimit'] = "9";
    item['document_key'] = "TnlZIbZC5pPUA08FWynLasnauYT2";
    item['activeProfileId'] = "REZ6X8NTibezfUScLeG8Tkt9Trv2";
    item['receiveNotifications'] = "0";
    item['serialnumberLicense'] = "012345";
    item['name'] = "Roger Cottam";
    item['profileLimit'] = "5";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fcmTokens'] = ["iPhone13,1-|-eaeut1HdN0ODsPMnQTR--U:APA91bGFP6tcxf1aivGDEt2B3RPIb6fDCxmYJhFqiC_XimsJ_U6TuWk2IavQIYGTjDNeDNXvVouMvlp6E65iwcGVdlI8M4CkWLQ3DE55BDNOlRVyZsv1RSBFWPg6Bej614Cfp392GmRv"];
    item['acceptedDisclaimer'] = "1";
    item['subscriptionLicense'] = "SashoM";
    item['activationLimit'] = "8";
    item['document_key'] = "jxcEP2zuI8hjMqTynCdjwlwHma02";
    item['activeProfileId'] = "jxcEP2zuI8hjMqTynCdjwlwHma02";
    item['name'] = "Sasho MacKenzie";
    item['profileLimit'] = "100";
    item.key = key++;
    
    item = {};
    this.items.push(item);
    item['fcmTokens'] = ["Simulator (iPhone13,1)-|-deLV6nDqwkblpFl4fyQQuB:APA91bGMCf-7riHTUTyqgg2aUIPbMN7wSI86Ee36V1ULydcnV3UTXF1iiKgbyJqI0LVzLG1k6av6TQ4r-b2qQkkxN1NhwJ1x3btMPNN-QU_xCYYeqRI5A-e43g3aEM893hFFv3Oav8Y6"];
    item['acceptedDisclaimer'] = "1";
    item['subscriptionLicense'] = "LynnzieC";
    item['activationLimit'] = "9";
    item['sharecode'] = "777777";
    item['sharecodeLicense'] = "777777";
    item['document_key'] = "wUo1oMvxA0axXGenjczhkNAPyFT2";
    item['activeProfileId'] = "wUo1oMvxA0axXGenjczhkNAPyFT2";
    item['name'] = "Lynnzie Cottam";
    item['profileLimit'] = "20";
    item.key = key++;
  }

  
  // this function's implementation is provided by React Studio.
  _fetchComplete = (err, options) => {
    if (err) {
      console.log('** Web service write failed: ', err, options);
    } else {
      if (this.updateCb) this.updateCb(this);
    }
  }
  
  
  addItem(item, options) {
    console.log("add to firebase: ", item);
      
    let isCollectionGroup = options.servicePath.startsWith("group:");
    if (isCollectionGroup) {
      console.log("unable to add to collection group: ", options.servicePath.substring(6));
      return;
    }
  
    const db = this.firebase.firestore();
    const collection = db.collection(options.servicePath);
  
    let addItemPromise;
    if (item.document_key && item.document_key.length > 0) {
      let parsedItem = (({ document_key, ...item }) => (item))( item );
      addItemPromise = collection.doc(item.document_key).set(parsedItem);
    } else {
      addItemPromise = collection.add(item);
    }
    addItemPromise
      .then((docRef) => {
        var addedKey="";
        if (docRef) {
          console.log("Firebase document added with id: ", docRef.id);
          addedKey=docRef.id;
          item.document_key = docRef.id;
          item.document_path = docRef.path;
        } else {
          console.log("Firebase document added with id: ", item.document_key);
          addedKey=item.document_key;
        }   
        if (this.onLastAddedDocumentKeyChanged != null) {
          this.onLastAddedDocumentKeyChanged(addedKey);
        }
        //super.addItem(item, options);
      
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error adding document: ", error);
        this._fetchComplete(error, options);
      });
  }
  
  removeItem(item, options) {
    //super.removeItem(item, options);
    
    var r = window.confirm(`Are you sure you want to delete this item? ${item.document_path}`);
    if (r == true) {
      console.log("delete from firebase: ", item);
  
      const db = this.firebase.firestore();
      //const collection = db.collection(options.servicePath);
      //const docRef = collection.doc(item.document_key);
      const docRef = db.doc(item.document_path);
  
      docRef.delete()
        .then(() => {
          this._fetchComplete(null, options);
        })
        .catch((error) => {
          console.error("Error deleting document: ", error);
          this._fetchComplete(error, options);    
        });
    }
  }
  
  replaceItemByRowIndex(idx, item, options) {
    //super.replaceItemByRowIndex(idx, item, options);
    
    console.log("update in firebase: ", item);
  
    const db = this.firebase.firestore();
    //const collection = db.collection(options.servicePath);
    //const docRef = collection.doc(item.document_key);
    const docRef = db.doc(item.document_path);
      
    docRef.set((({ key, document_ref, document_key, ...item }) => (item))( item ), {merge: true})
      .then(() => {
        this._fetchComplete(null, options);
      })
      .catch((error) => {
        console.error("Error updating document: ", error);
        this._fetchComplete(error, options);    
      });
  }
  

}
