import React, { Component } from 'react';
import firebase from 'firebase';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';

export default class FirebaseLogin extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
      isSignedIn: false
    };

    this.autosignInEnabled = false;
    
    this.firebaseUIConfig = {
      signInFlow: 'popup',
      signInOptions: [
          'apple.com',
        ],
      callbacks: {
        signInSuccessWithAuthResult: (authResult, redirectUrl) => {
          //var credential = authResult.credential;
          //var isNewUser = authResult.additionalUserInfo.isNewUser;
          //var providerId = authResult.additionalUserInfo.providerId;
          //var operationType = authResult.operationType;
          return false;
        },
        signInFailure: (error) => {
          console.error("** Firebase sign-in failed: ", error);
          this.loginFailed(error);
          return false;
        },
      }
    };
  }
  
  saveCurrentUserDataInApp() {
    var user = firebase.auth().currentUser;
    var userName = user.displayName;
    var email = user.email;
    var userPhotoUrl = user.photoURL;
    var userId = user.uid;
    var isAdmin = (
      user.uid === '4WuS2cvo8VNQlkd5icNgIyf5qvm1' ||
      user.uid === 'dqxRbhhGBrR50XqAH27Yxzdbwyi2' ||
      user.uid === 'wjOgs2YQo6SuRnzCoV9jjjAy7xW2' ||
      user.uid === 'YBxP5J4uSVcNHO35Y9Br8XZL61Q2' ||
      user.uid === 'qXwv1ACWhEYu1AeP7Yqrlc0oZru1' ||
      user.uid === 'DAsV2EXOMMWSl2XTcIEy6An5wRf2' ||
      user.uid === 'oIWLIR9PV8UZHnA9yoQNgi63KSD2' ||
      user.uid === 't4dkNZYCtvO7inWUp9tfpddNiKv1' ||
      user.uid === 'ayM3oeJj3vbSrTxhwoMg4vbwlL43' ||
      user.uid === 'ulJiP3EAn5f3BdtR4WKIGIwVZbm2' ||
      user.uid === 'n3kU2ABbHHYzurfqRErAYZLDHG73' ||
      user.uid === 'kgNDupZdMkQQACfn0yPgGmtabEN2' ||
      user.uid === 'ilbUiYrOF9TIqx3YxTn3zMIAVq52'
    );
    var userPhoneNumber=user.phoneNumber;
    console.log("got user: %s, %s, %s, %s, %s", userName, email, userId, isAdmin, userPhoneNumber );
    
    ((v) => { this.props.appActions.updateDataSlot("ds_username", v) })(userName || 'Anonymous');
    ((v) => { this.props.appActions.updateDataSlot("ds_useremail", v) })(email || '');
    ((v) => { this.props.appActions.updateDataSlot("ds_userphoto", v) })(userPhotoUrl);
    ((v) => { this.props.appActions.updateDataSlot("ds_userid", v) })(userId);
    ((v) => { this.props.appActions.updateDataSlot("ds_isAdmin", v) })(isAdmin);
    
    ((v) => { this.props.appActions.updateDataSlot("ds_userphone", v) })(userPhoneNumber);
        
  }

  loginSuccess() {
    let targetTable_loginGoToPage = (targetId) => {
      if (targetId === 'licensed')
        return 'registration';
      else if (targetId === 'not-licensed')
        return 'registration';
    }
    
    let transformTargetScreenValue_loginGoToPage = (input) => {
      // This function modifies the target screen value of switch 'LoginGoToPage'.
      // There is a variable named 'input' that provides the target screen's name.
      // The function may implement custom logic to return a screen name dynamically based on the input value.
      //
      if (!input || input==='') {
        return 'not-licensed';
      }
      return 'licensed';
    }
    
    
    sessionStorage.setItem('loginStatus_login', 'active');
    this.props.appActions.goToScreen(targetTable_loginGoToPage(transformTargetScreenValue_loginGoToPage((this.props.appActions.dataSlots ? this.props.appActions.dataSlots['ds_username'] : ''))));
    

  }
  
  loginFailed(err) {
    // This is typically never called by Firebase UI because the sign-in flow is handled as a popup.
    // We're including this code from React Studio as documentation for other plugins.
    sessionStorage.setItem('loginStatus_login', 'failed');
    this.props.appActions.goToScreen('login', { errorText: ''+err });
    

  }
  
  componentDidMount() {
    this.unregisterAuthObserver = firebase.auth().onAuthStateChanged(
        (user) => {
          this.setState({isSignedIn: !!user});
          if (user != null) {
            this.saveCurrentUserDataInApp();
            this.loginSuccess();
          }
        }
    );
  }

  componentWillUnmount() {
    this.unregisterAuthObserver();
  }

  render() {
    if ( !this.state.isSignedIn) {
      return (
        <StyledFirebaseAuth uiCallback={ui => {if (!this.autosignInEnabled) ui.disableAutoSignIn();}} uiConfig={this.firebaseUIConfig} firebaseAuth={firebase.auth()}/>
      )
    } else {
      return null;
    }
  }
}
